import React, { useEffect, useState } from 'react';
import { Layout, Typography, Space, Row, Col } from 'antd';
import { FacebookOutlined, TwitterOutlined, LinkedinOutlined, InstagramOutlined } from '@ant-design/icons';
import { getHeaderHeight } from './utils';

const { Footer } = Layout;
const { Text, Link } = Typography;

const AppFooter = () => {
  const [headerHeight, setHeaderHeight] = useState(0);
  
  useEffect(() => {
    const updateHeaderHeight = () => {
        setHeaderHeight(getHeaderHeight());
    }

    updateHeaderHeight();
    window.addEventListener('resize', updateHeaderHeight);

    return () => {
        window.removeEventListener('resize', updateHeaderHeight);
    };
  }, []);

  return (
    <Footer className="app-footer">
      <Row justify="space-between" align="middle">
        <Col>
          <Space>
            <Link to="landing-page-v3" smooth={true} duration={500} offset={-headerHeight}>Home</Link>
            {/* <Link href="#home">Home</Link> */}
            <Link href="#services">Services</Link>
            <Link href="#about">About</Link>
            <Link href="#contact">Contact</Link>
          </Space>
        </Col>
        <Col>
          <Space>
            <Link href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <FacebookOutlined className="social-icon" />
            </Link>
            <Link href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
              <TwitterOutlined className="social-icon" />
            </Link>
            <Link href="https://www.linkedin.com/company/maroondev" target="_blank" rel="noopener noreferrer">
              <LinkedinOutlined className="social-icon" />
            </Link>
            <Link href="https://www.instagram.com/maroondev" target="_blank" rel="noopener noreferrer">
              <InstagramOutlined className="social-icon" />
            </Link>
          </Space>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: '20px' }}>
        <Text>&copy; {new Date().getFullYear()} Maroonz Tech Pvt Ltd. All rights reserved.<br/>info@maroondev.fyi<br/>+91 - 9354412740</Text>
      </Row>
    </Footer>
  );
};

export default AppFooter;
