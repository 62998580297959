import { useState, useEffect } from "react";
import { Card, List, Typography, Spin } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import "./MediumBlogs.css";

const { Title, Text } = Typography;

const MediumBlogs = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchMediumPosts = async () => {
            try {
                const response = await fetch(
                    `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@maroondev.fyi`
                );
                const data = await response.json();
                console.log(data.items);
                setPosts(data.items);
            } catch (error) {
                console.error("Error fetching Medium posts:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchMediumPosts();
    }, []);

    return (
        <div className="medium-blogs-container">
            <Title level={4} className="title">"You only know as much as you express."</Title>
            {loading ? (
                <Spin size="large" className="loading-spinner" />
            ) : (
                <List className="blogs-list-container"
                    grid={{ gutter: 16, column: 1 }}
                    dataSource={posts}
                    renderItem={(post) => (
                        <List.Item className="blog-list-item">
                            <Card
                                hoverable
                                className="blog-card"
                                // cover={
                                //     <img
                                //         alt={post.title}
                                //         // src={post.thumbnail || "https://placehold.co/400/lightblue/white"}
                                //         src={post.thumbnail || "https://i.ibb.co/3YkBzYSL/blog-placehlolder-image-big-new.png"}
                                //         className="blog-image"
                                //     />
                                // }
                            >
                                <Title level={4} className="blog-title">{post.title}</Title>
                                <Text className="blog-description">{post.description.replace(/<[^>]+>/g, "").substring(0, 150)}...</Text>
                                <Text type="secondary">{new Date(post.pubDate).toDateString()}</Text><br/><br/>
                                <a href={post.link} target="_blank" rel="noopener noreferrer" className="read-more">
                                    Read More <LinkOutlined />
                                </a>
                            </Card>
                        </List.Item>
                    )}
                />
            )}
        </div>
    );
};

export default MediumBlogs;
