import React, { useRef, useState, useEffect } from "react";
import { Button, Card, message, Row, Col } from "antd";
import Webcam from "react-webcam";
import * as tf from "@tensorflow/tfjs";
import * as faceDetection from "@tensorflow-models/face-detection";
import "antd/dist/reset.css";
import axios from "axios";
import './FaceLivenessV3.css';
import { MAROONZ_BACKEND_BASE_URL } from '../../constants';

const FaceLivenessV3 = () => {
    const webcamRef = useRef(null);
    const intervalRef = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [camSize, setCamSize] = useState(0);
    const [livenessVerified, setLivenessVerified] = useState(false);
    const [capturedImage, setCapturedImage] = useState(null);
    const [matchResult, setMatchResult] = useState(null);
    const [matchConfidence, setMatchConfidence] = useState(0);
    const [faceBox, setFaceBox] = useState(null);
    const [failureReason, setFailureReason] = useState("");  // Add this state
    const [faceKeypoints, setFaceKeypoints] = useState([]);
    const [checkpointsPassed, setCheckpointsPassed] = useState(false);
    const [checklist, setChecklist] = useState({
        faceDetected: false,
        clockwiseMovement: false,
        antiClockwiseMovement: false,
        turnedLeft: false,
        turnedRight: false,
        turnedUp: false,
        turnedDown: false,
        facePercentageFlag: false,
    });

    // Function to check face direction
    const checkFaceDirection = (faces, videoElement) => {
        if (faces.length === 0) {
            console.log("No face detected");
            // message.error("No face detected");
            setFailureReason("No face detected");
            return false;
        }

        const face = faces[0];
        // console.log("FACE IN DIRECTION FUNC: ", face);
        const landmarks = face.keypoints;

        // console.log("LANDMARKS: ", landmarks);

        // Get the positions of the eyes and nose
        const leftEye = landmarks.find(pt => pt.name === "leftEye");
        const rightEye = landmarks.find(pt => pt.name === "rightEye");
        const nose = landmarks.find(pt => pt.name === "noseTip");

        if (!leftEye || !rightEye || !nose) {
            console.log("Missing facial landmarks");
            setFailureReason("Missing facial landmarks");
            return false;
        }

        // console.log("FEATURES: ", leftEye, rightEye, nose);

        // Calculate the face's center (midpoint between eyes and nose)
        const faceCenterX = nose.x; // (leftEye['x'] + rightEye['x']) / 2;
        const faceCenterY = nose.y; // (leftEye['y'] + rightEye['y']) / 2;

        // Get the video dimensions and calculate face size
        const videoRect = videoElement.getBoundingClientRect();
        const videoWidth = videoRect.width;
        const videoHeight = videoRect.height;

        // Check if the face is covering at least 75% of the video space
        const eyeDistance = Math.abs(leftEye['x'] - rightEye['x']);
        const faceWidth = eyeDistance * 2; // Rough estimate of face width
        const faceHeight = Math.abs(leftEye['y'] - nose['y']) * 2; // Rough estimate of face height

        const faceCoverPercentage = (faceWidth / videoWidth) * 100; // Percentage of the video width
        const faceHeightPercentage = (faceHeight / videoHeight) * 100; // Percentage of the video height

        // if (faceCoverPercentage < 10 || faceHeightPercentage < 10) {
        //     console.log("Face does not cover 50% of the video space");
        //     message.error("Face does not cover enough space (at least 50%)");
        //     setFailureReason("Face does not cover enough space (at least 50%)");
        //     return false;
        // }

        // Check horizontal (yaw) and vertical (pitch) deviations from center
        const horizontalDeviation = faceCenterX - ((leftEye.x + rightEye.x) / 2);
        const verticalDeviation = faceCenterY - ((leftEye.y + rightEye.y) / 2);


        if (horizontalDeviation > videoWidth * 0.1) {
            console.log("Face is not centered horizontally");
            // message.error("Face is not centered horizontally");
            setFailureReason("Face is not centered horizontally");
            return false;
        }
        if (verticalDeviation > videoHeight * 0.2) {
            console.log("Face is not centered vertically");
            // message.error("Face is not centered vertically");
            setFailureReason("Face is not centered vertically");
            return false;
        }

        // Define thresholds for looking left, right, up, down, and straight ahead
        const horizontalThreshold = eyeDistance * 0.7;
        const verticalThreshold = eyeDistance * 0.7;

        let gazeDirection = '';

        // console.log("VALUES: ", horizontalDeviation, horizontalThreshold, verticalDeviation, verticalThreshold)
        setChecklist((prev) => ({ ...prev, turnedRight: true }));
        if (Math.abs(horizontalDeviation) < horizontalThreshold && Math.abs(verticalDeviation) < verticalThreshold) {
            gazeDirection = "Straight Ahead";
        } else if (horizontalDeviation < -horizontalThreshold) {
            gazeDirection = "Looking Left";
            setChecklist((prev) => ({ ...prev, turnedLeft: true }));
        } else if (horizontalDeviation > horizontalThreshold) {
            gazeDirection = "Looking Right";
            setChecklist((prev) => ({ ...prev, turnedRight: true }));
        } else if (verticalDeviation < verticalThreshold) {
            gazeDirection = "Looking Up";
            setChecklist((prev) => ({ ...prev, turnedUp: true }));
        } else if (verticalDeviation > verticalThreshold + 5) {
            gazeDirection = "Looking Down";
            setChecklist((prev) => ({ ...prev, turnedDown: true }));
        }

        // console.log(`Gaze direction: ${gazeDirection}`);

        if (gazeDirection !== "Straight Ahead") {
            setFailureReason("Look straight ahead into the camera.");
        }

        // Return whether the person is looking straight ahead and the face is sufficiently centered
        return gazeDirection === 'Straight Ahead';
    };

    useEffect(() => {
        let detector;  // Store detector instance globally

        const loadDetector = async () => {
            try {
                const model = faceDetection.SupportedModels.MediaPipeFaceDetector;
                const detectorConfig = {
                    runtime: 'mediapipe',
                    solutionPath: "https://cdn.jsdelivr.net/npm/@mediapipe/face_detection",
                    modelType: "full",
                };
                detector = await faceDetection.createDetector(model, detectorConfig);
            } catch (error) {
                console.error("Failed to load detector", error);
                message.error("Face detection model failed to load");
            }
        };

        const checkLiveness = async () => {
            if (!detector) return; // Avoid running without a detector

            try {
                const video = webcamRef.current.video;

                if (video && video.readyState === 4) {
                    const faces = await detector.estimateFaces(video);
                    if (faces.length > 0) {
                        setChecklist((prev) => ({ ...prev, faceDetected: true }));
                    }
                    console.log("PREDICTIONS BELOW:", faces);

                    const videoElement = document.getElementById('camera-column'); // Replace with your actual video element id
                    const videoRect = videoElement.getBoundingClientRect();

                    if (!checkFaceDirection(faces, videoElement)) {
                        setLivenessVerified(false);
                    }

                    if (faces.length > 0 && faces[0].box) {
                        const face = faces[0];
                        // Store keypoints (landmarks)

                        const { height, width, xMax, xMin, yMax, yMin } = faces[0].box;
                        const thisbox = faces[0].box

                        if (isMobile) {
                            setFaceKeypoints(
                                face.keypoints.map((point) => ({
                                    left: point.x - 100,
                                    top: point.y - 120,
                                }))
                            );

                            setFaceBox({
                                left: xMin - 100,
                                top: yMin - 120,
                                width: thisbox.width,
                                height: thisbox.height
                            });
                        } else {
                            setFaceKeypoints(
                                face.keypoints.map((point) => ({
                                    left: point.x - 40,
                                    top: point.y - 30,
                                }))
                            );
    
                            setFaceBox({
                                left: xMin - 40,
                                top: yMin - 30,
                                width: thisbox.width,
                                height: thisbox.height
                            });
                        }

                        const facePercentage = (thisbox.width * thisbox.height) / (videoRect.width * videoRect.height);
                        if (facePercentage >= 0.2) {
                            setChecklist((prev) => ({ ...prev, facePercentageFlag: true }));
                        } else {
                            setChecklist((prev) => ({ ...prev, facePercentageFlag: false }));
                            setCheckpointsPassed(false);
                            setLivenessVerified(false);
                        }
                    } else {
                        console.log("Box Not Found")
                        setFaceBox(null);
                    }
                }
            } catch (error) {
                console.error("Liveness detection failed", error);
                message.error("Error checking liveness");
            }
        };

        if (!capturedImage) {
            loadDetector();  // Load model once
            intervalRef.current = setInterval(checkLiveness, 200);
            return () => clearInterval(intervalRef.current);
        }
    }, []);

    const stopInterval = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current); // Stop interval
            intervalRef.current = null;
        }
    };

    useEffect(() => {
        if (checklist.faceDetected && checklist.turnedLeft && checklist.turnedRight && checklist.turnedDown && checklist.facePercentageFlag) {
            setCheckpointsPassed(true);
            setLivenessVerified(true);
        }
    }, [checklist]);

    const capture = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        console.log("CAPTURED IMAGE: ", imageSrc)
        setCapturedImage(imageSrc);
        stopInterval();
    };

    const handleFaceMatchV2 = async () => {
        // Extract chat_id from URL
        const urlParams = new URLSearchParams(window.location.search);
        const chatId = urlParams.get("chat_id");

        if (!chatId) {
            console.error("chat_id not found in URL");
            return;
        }

        const capturedImageBase64 = capturedImage.split(",")[1];

        try {
            const response = await fetch(MAROONZ_BACKEND_BASE_URL + "/facematch/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ chat_id: parseInt(chatId), captured_image: capturedImageBase64 }), // Sending image as JSON
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            console.log("Face match response:", data);

            if (data.status) {
                setMatchResult(true);
                setMatchConfidence(data.data.confidence)
                message.info(data.message)
            } else {
                setMatchResult(false);
                message.error(data.message)
            }

            return data;
        } catch (error) {
            message.error("Face match API failed, please try later.")
            console.error("Error matching face:", error);
            return null;
        }
    }

    const handleRetakePhoto = () => {
        window.location.reload();  // This will reload the page
    };

    useEffect(() => {
        const updateSize = () => {
            const camContainer = document.getElementById('camera-column');

            if (camContainer) {
                const { width, height } = camContainer.getBoundingClientRect();
                console.log("CAM SIZE: ", Math.min(width, height));
                setCamSize(200); // Set size as the smaller value
            }
        };

        // updateSize();
        setTimeout(updateSize, 100); // Delay execution slightly to ensure DOM is ready
        window.addEventListener("resize", updateSize); // Update on resize
        return () => window.removeEventListener("resize", updateSize);
    }, []);

    return (
        <div className="face-page-container">
            {!capturedImage && (<Row style={{
                width: '100vw',
                minHeight: '80vh'
            }}>
                <Col xs={24} md={6}>
                    <div className="checklist-container">
                        <h3>Please ensure the below checkpoints are done:</h3>
                        <ul>
                            {/* <li className={checklist.faceDetected ? "checked" : ""}>Face Detected</li> */}
                            <li className={checklist.turnedRight ? "checked" : ""}>{checklist.turnedRight && "✓ "}<span>Look to your Left</span></li>
                            <li className={checklist.turnedLeft ? "checked" : ""}>{checklist.turnedLeft && "✓ "}<span>Look to your Right</span></li>
                            {/* <li className={checklist.turnedUp ? "checked" : ""}>Look up</li> */}
                            <li className={checklist.turnedDown ? "checked" : ""}>{checklist.turnedDown && "✓ "}<span>Look down</span></li>
                            <li className={checklist.facePercentageFlag ? "checked" : ""}>{checklist.facePercentageFlag && "✓ "}<span>Move closer to camera</span></li>
                        </ul>
                    </div>
                </Col>
                <Col xs={24} md={9} id="camera-column" >
                    <Card id="cam-card" className="cam-card" bodyStyle={{ padding: 0, backgroundColor: 'rgb(243,243,243)', border: 'none' }}>
                        <div>
                            <Webcam
                                id="cam-container"
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                className="cam-container"
                                videoConstraints={{ facingMode: "user" }}
                                style={{
                                    borderColor: livenessVerified ? "green" : "red",
                                    borderWidth: "5px",
                                }}
                            />
                            {faceBox && (
                                <div
                                    className="face-box"
                                    style={{
                                        left: faceBox.left,
                                        top: faceBox.top,
                                        width: faceBox.width,
                                        height: faceBox.height,
                                        position: "absolute",
                                        backgroundColor: "yellow",
                                        opacity: "0.1"
                                    }}
                                />
                            )}
                            {faceKeypoints.map((point, index) => (
                                <div
                                    key={index}
                                    className="face-key-point"
                                    style={{
                                        left: `${point.left}px`,
                                        top: `${point.top}px`,
                                        width: "3px",
                                        height: "3px",
                                        position: "absolute",
                                        backgroundColor: "blue"
                                    }}
                                />
                            ))}
                        </div>
                    </Card>
                </Col>
                <Col xs={24} md={9}>
                    <div className="capture-instruction">
                        <p>Please ensure you're looking straight into the camera, with your face covering around 75% of the camera space.</p>
                    </div>
                    <Button disabled={!livenessVerified || capturedImage} type="primary" onClick={capture} className="mt-4 w-full kyc-primary-button">Capture Photo</Button>
                    {!checkpointsPassed && (
                        <div className="failure-reason">
                            <p style={{
                                color: "red",
                                fontWeight: "700",
                                fontSize: "20px"
                            }}>
                                Missing checkpoints: <br />
                            </p>
                            <p className="failure-reason missing-checkpoints-description">Please ensure all checkpoints pass. <br /> Move your head to the left, right and down.<br /> Stay close to the camera.</p>
                        </div>
                    )}
                    {!livenessVerified && checkpointsPassed && failureReason && (
                        <div className="failure-reason">
                            <p style={{
                                color: "red",
                                fontWeight: "700",
                                fontSize: "20px"
                            }}>
                                Liveness Verification Failed: <br />
                            </p>
                            <p className="failure-reason">{failureReason}</p>
                        </div>
                    )}
                </Col>
            </Row>
            )}
            {capturedImage && (<Row style={{
                margin: '50px auto'
            }}>
                <Col xs={24} md={2}></Col>
                <Col xs={24} md={9}>
                    {capturedImage && (
                        <div className="captured-image-container">
                            <h3>Captured Photo</h3>
                            <img src={capturedImage} alt="Captured" className="captured-image" />
                        </div>
                    )}
                </Col>
                <Col xs={24} md={12}>
                    {capturedImage && (
                        <div>
                            <div className="match-instruction">
                                <p>Before submitting the photo, please ensure that the image is clear, you're looking into the camera and the face covers around 75% of the image.</p>
                            </div>
                            <Button disabled={matchResult !== null ? true : false} type="primary" onClick={handleFaceMatchV2} className="kyc-primary-button">Submit for matching</Button>
                            {matchResult !== null && (
                                <div style={{
                                    color: matchResult ? "green" : "red"
                                }} className="match-result">
                                    {matchResult ? <div><span className="match-title">Face Match Successful</span><br /><span className="match-description">Please continue to Maroonzbot to complete the eKYC process.</span></div> : <div><span className="match-title">Face Match Failed</span><br /><span className="match-description">Please retake the photo and try again.</span></div>}
                                </div>
                            )}
                            <br />
                            {matchResult && (
                                <div>
                                    <Button
                                        id="go-back-to-chat-button"
                                        type="primary"
                                        onClick={() => window.location.href = 'https://t.me/maroonzbot'}
                                        className="kyc-primary-button"
                                    >Continue eKYC
                                    </Button>
                                </div>
                            )}
                            {!matchResult && <Button type={matchResult === false ? "primary" : "dashed"} onClick={handleRetakePhoto} className={matchResult === false ? "kyc-primary-button" : "kyc-secondary-button"}>Retake Photo</Button>}
                        </div>
                    )}
                </Col>
            </Row>
            )}
        </div>
    );
};

export default FaceLivenessV3;
