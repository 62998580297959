import React, { useRef, useState, useEffect } from "react";
import { Button, Card, message } from "antd";
import Webcam from "react-webcam";
import * as tf from "@tensorflow/tfjs";
import * as blazeface from "@tensorflow-models/blazeface";
import "antd/dist/reset.css";
import axios from "axios";
import './FaceLivenessV2.css';

const FaceLivenessV2 = () => {
  const webcamRef = useRef(null);
  const [livenessVerified, setLivenessVerified] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [matchResult, setMatchResult] = useState(null);

  useEffect(() => {
    const checkLiveness = async () => {
      try {
        const net = await blazeface.load();
        const video = webcamRef.current.video;
        
        if (video) {
          const predictions = await net.estimateFaces(video, false);
          console.log("PREDICTIONS BELOW:")
          console.log(predictions)
          setLivenessVerified(predictions.length > 0);
          if (predictions.length === 0) {
            message.error("Liveness check failed, please remove any obstructions and ensure the face is more than 75% part of the camera area.");
            setLivenessVerified(false);
          } else {
            // message.info("Face detected! ")
            setLivenessVerified(true);
          }
        }
      } catch (error) {
        console.error("Liveness detection failed", error);
        message.error("Error checking liveness");
      }
    };

    const interval = setInterval(checkLiveness, 1000);
    return () => clearInterval(interval);
  }, []);

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
  };

  const handleFaceMatch = async () => {
    try {
      const aadhaarImage = await fetchAadhaarImage();
      const response = await axios.post("/api/face-match", {
        capturedImage,
        aadhaarImage,
      });
      setMatchResult(response.data.match);
      message.success(response.data.match ? "Face match successful" : "Face match failed");
    } catch (error) {
      console.error("Face match failed", error);
      message.error("Error matching face");
    }
  };

  const fetchAadhaarImage = async () => {
    return "data:image/jpeg;base64,sample_aadhaar_image";
  };

  return (
    <div className="flex flex-col items-center p-4 min-h-screen bg-gray-100 face-page-container">
      <Card className="p-4 flex flex-col items-center shadow-md w-full max-w-sm rounded-lg">
        <div className="w-64 h-64">
          <Webcam
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            className="w-full h-full cam-container"
            videoConstraints={{ facingMode: "user" }}
            style={{
                borderColor: livenessVerified ? "green": "red",
            }}
          />
        </div>
        <Button type="primary" onClick={capture} className="mt-4 w-full">Capture</Button>
        {capturedImage && (
          <div className="mt-4 flex flex-col items-center captured-image-container">
            <img src={capturedImage} alt="Captured" className="w-32 h-32 rounded-md shadow-md" />
            <Button type="dashed" onClick={handleFaceMatch} className="mt-2 w-full">Match Face</Button>
          </div>
        )}
        {matchResult !== null && (
          <p className={`mt-4 text-lg font-semibold ${matchResult ? "text-green-500" : "text-red-500"}`}>
            {matchResult ? "Face Match Successful" : "Face Match Failed"}
          </p>
        )}
      </Card>
    </div>
  );
};

export default FaceLivenessV2;
